import React, { useEffect, useState } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, MenuItem, TextField, FormControl, FormHelperText } from '@material-ui/core';
import { renderSelectField, renderDateField, renderTextField, renderRadioGroup } from '../form/renderInputsForm';
import SearchProvinceComponent from '../province/SearchProvinceComponent';
import moment from 'moment';
import { useStyles } from './styles';
import { getSinisterPolicyCoverage } from '../../actions/sinisterActions';
import { showError } from '../../actions/notificationActions';
import { getFileSinisterByCodRamTec, getSinisterCollisionTypes } from '../../actions/sinisterActions';
import { SHOW_COLISSION_TYPE_RISK_TARGET_IDS, SINISTER_CAUSE_STEALING_ID, SINISTER_CAUSE_PARTIAL_DAMAGE_WINDSHIELD_GLASS_ID } from '../../utils/urls';


const validate = (values) => {
    const errors = {};
    const requiredFields = [
        'registerDate',
        'sinisterCause',
        'cover',
        'descriptionSinister',
        'colissionType',        
        'postalCode',
        'street'
    ];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    return errors;
};

const sinisterCauseDetails1 = [
    { value: "luneta", label: "Luneta" },
    { value: "parabrisa con sensor", label: "Parabrisa con sensor" },
    { value: "parabrisa sin sensor", label: "Parabrisa sin sensor" },
    { value: "cristales", label: "Cristales" }
];

const sinisterCauseDetails2 = [
    { value: "cubierta de posición", label: "Cubierta de posición" },
    { value: "cubierta de auxilio", label: "Cubierta de auxilio" },
    { value: "cerradura", label: "Cerradura" },
    { value: "bateria", label: "Bateria" },
    { value: "varios", label: "Varios" },
];

function DetailsGeneralForm({ handleSubmit, handleStep, coberturas, policy }) {
    const dispatch = useDispatch();
    const [registerDate, setRegisterDate] = useState();
    const [coverages, setCoverages] = useState([]);
    const classes = useStyles();
    const { claimCauses } = useSelector((state) => state);
    const [causes, setCauses] = useState([]);
    const { auth } = useSelector((state) => state);
    const [isDisableColissionType, setIsDisableColissionType] = useState(null);
    const [isActiveMeasure, setIsActiveMeasure] = useState(false);
    const [sinisterCauseDetailsOption, setSinisterCauseDetailsOption] = useState(null);
    const { token } = auth;
    const sinisterDetail = useSelector((state) => state.sinister.details);
    const incidenceDate = useSelector((state) => state.form.detailpolicy.values.incidenceDate);
    const nro_item = useSelector((state) => state.form.detailpolicy.values.items.nro_item);  
    const policyCoverage = useSelector((state) => state.sinister.policyCoverage); 
    
    const { sinister: {collisionTypes} } = useSelector((state) => state);

    const showAditionalDetails = sinisterCauseDetailsOption == 1 || sinisterCauseDetailsOption == 2;

    useEffect(() => {
        const firstRun = async() => {
            await dispatch(getSinisterCollisionTypes(token));
        }

        firstRun()
    }, []);


    useEffect(() => {
        const today = moment().format('MM/DD/YYYY');
        dispatch(change('detailgeneral', 'registerDate', today));
        setRegisterDate(today);
    }, []);

    useEffect(() => {
        setCoverages(policyCoverage?.data?.result?.result)
    }, [policyCoverage]);

    useEffect(() => {
        setCauses(claimCauses?.data?.data || []);
    }, [claimCauses?.data?.data]);

    useEffect(() => {        
        if(SHOW_COLISSION_TYPE_RISK_TARGET_IDS && sinisterDetail.data && sinisterDetail.data.length && sinisterDetail.data[0] && sinisterDetail.data[0].Cod_ramo){           
            //4 = automotores
            //24 = moto
            const riskId = sinisterDetail.data[0].Cod_ramo;
            const disableColissionType_ = !JSON.parse(SHOW_COLISSION_TYPE_RISK_TARGET_IDS.includes(riskId));

            dispatch(change('detailgeneral', 'colissionType', "0"));
            setIsDisableColissionType(disableColissionType_)
        }
    }, [sinisterDetail]);

    const handleSubmits = (values) => {
        handleStep({
            step: 'policy'
        });
    };

    const getEndorsementNumber = () => {
        if (sinisterDetail.data.length > 0) {
            let endorsements = sinisterDetail.data.filter((item) => 
                moment(incidenceDate).isAfter(moment(item.Vig_desde, "DD-MM-YYYY").format("YYYY-MM-DD")) && 
                moment(incidenceDate).isBefore(moment(item.Vig_hasta, "DD-MM-YYYY").format("YYYY-MM-DD"))
            )
            if (endorsements.length > 0 && endorsements[0] !== undefined) {
                return endorsements[0].Endoso
            } else {
                dispatch(showError('La fecha de ocurrencia del siniestro no se encuentra entre las fechas de los endosos de la poliza seleccionada'))
            }
        }
    }

    const handleChangeSinisterCause = async (e) => {
        setSinisterCauseDetailsOption(null);
        setIsActiveMeasure(false);

        const sinisterCauseCode = e.target.value;
        let nro_endo = getEndorsementNumber();
        await dispatch(
            getSinisterPolicyCoverage(token, policy.nro_poliza, nro_endo, policy.codigo_ramo, sinisterCauseCode, nro_item)
        );
    }

    const activeStealingForm = (causeCode) =>{
        if(!causeCode) return
        console.log("causeCode",causeCode)
        const exist = Number(causeCode) == 23 || Number(causeCode) == 7; //"ROBO/HURTO TOTAL" //todo:colocar el 7 como variable de entorno

        localStorage.setItem('isActiveStealingForm', !!exist);
    }

    const handleChangeCause = async (e) => {
        try {
            const causeCode = e.target.value;

            console.log("causeCode: ",causeCode)
            const optionSinesterCauseDetail = causeCode == SINISTER_CAUSE_PARTIAL_DAMAGE_WINDSHIELD_GLASS_ID ? 1 : causeCode == SINISTER_CAUSE_STEALING_ID ? 2 : null;
            setSinisterCauseDetailsOption(optionSinesterCauseDetail);
    

            const items = coverages.filter((element) => element.cod_cober === causeCode)
            if (items.length > 0) {
                dispatch(change('detailgeneral', 'cod_ind_cob', items[0].cod_ind_cob));
                await dispatch(
                    getFileSinisterByCodRamTec(items[0].cod_ramo_tec)
                );
            }

            //Activar el formulario de hurto
            activeStealingForm(causeCode)

        } catch (error) {
            console.log('Error handleChangeCause', error);
        }

    }

    const handleAditionalDetail = (e) => {
        const specificDetail = e.target.value;

        if(specificDetail == "cubierta de posición" || specificDetail == "cubierta de auxilio"){
            setIsActiveMeasure(true)
        }else{
            setIsActiveMeasure(false)
        }

        dispatch(change('detailgeneral', 'specificDetailCause', specificDetail));
    }

    return (
        <form onSubmit={handleSubmit(handleSubmits)}>
            <Grid container direction="row">
                <Grid item xs={12} md={4} style={{ padding: 8 }}>
                    <Field
                        name="registerDate"
                        label="Fecha de registro"
                        value={registerDate}
                        dateValue={registerDate}
                        component={renderDateField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4} style={{ padding: 8 }}>
                    <Field
                        name="colissionType"
                        label="Tipo de colisión"
                        component={renderSelectField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                        disabled={isDisableColissionType} //is disable if is different of moto, automotores
                    >
                        {
                            collisionTypes.data !== undefined && collisionTypes.data.length > 0 ?
                            (
                                collisionTypes.data.map((item, index) =>
                                    <MenuItem key={`collisionTypes-${index}`} value={item.Id}>
                                        {item.Description}
                                    </MenuItem>
                                )
                            ) :(
                                <MenuItem value=''>
                                    No hay elementos que mostrar
                                </MenuItem>
                            )
                        }
                    </Field>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <SearchProvinceComponent
                    defaultvalues={{
                        country: '1',
                        province: '1'
                    }}
                    formName="detailgeneral"
                />
                <Grid item xs={12} md={6} style={{ padding: 8 }}>
                    <Field
                        name="sinisterCause"
                        label="Causa del siniestro"
                        component={renderSelectField}
                        custom={{ tabIndex: 6 }}
                        onChange={handleChangeSinisterCause}
                    >
                        {causes &&
                            causes.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.ClaimCauseCode}
                                >
                                    {item.Description}
                                </MenuItem>
                            ))}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: 8 }}>
                    <Field
                        name="cover"
                        label="Cobertura"
                        component={renderSelectField}
                        custom={{ tabIndex: 6 }}
                        onChange={handleChangeCause}
                    >
                        {
                            coverages && coverages.length > 0 ?
                            coverages.map((item, index) => (
                                <MenuItem key={`coverage-${index}`} value={item.cod_cober}>
                                    {item.txt_desc}
                                </MenuItem>
                            )) : (<MenuItem value=''>
                                No existen coberturas para esta causa
                            </MenuItem>)
                        }
                    </Field>
                </Grid>

                {showAditionalDetails && <Grid item xs={6} md={6} style={{ padding: 8 }}>
                    <FormControl component="fieldset">
                        <FormHelperText>Detalles de daños adicionales</FormHelperText>
                        <Field
                        name="medio"
                        component={renderRadioGroup}
                        onChange= {handleAditionalDetail}
                        style={{ display: "flex", paddingLeft: "50px" }}
                        custom={{
                            tabIndex: 5,
                            options: sinisterCauseDetailsOption == 1 ? sinisterCauseDetails1 : sinisterCauseDetailsOption == 2 ? sinisterCauseDetails2 : [],
                        }}
                        />
                    </FormControl>
                </Grid>}

                {isActiveMeasure && <Grid item xs={12} md={12} style={{ padding: 8 }}>
                    <Field 
                        name="measure" 
                        label="Medida" 
                        component={renderTextField}
                        custom={{ tabIndex: 6 }}
                    />
                </Grid>}


                <Grid item xs={12} md={12} style={{ padding: 8 }}>
                    <Field 
                        name="descriptionSinister" 
                        label="Descripcion detallada de como ocurrio" 
                        component={renderTextField}
                        custom={{ tabIndex: 6 }}
                        rows={4}
                        multiline
                    />
                </Grid>

                <Grid item xs={12} md={12} style={{ padding: 0 }}>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            className={classes.buttonNextStep}
                        >
                            Siguiente
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

DetailsGeneralForm.propTypes = {};

export default reduxForm({ form: 'detailgeneral', validate })(DetailsGeneralForm);
